<template>
  <div id="risk">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">High risk warning</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-danger" v-if="risk">At risk</span>
          <span class="has-text-success" v-else>Nothing reported</span>
        </p>
      </div>
    </div>
    <div v-if="risk" class="box">
      <p class="title is-6 has-text-danger">
        <span class="icon">
          <i class="fal fa-exclamation-triangle" />
        </span>
        <span>High risk vehicle reported</span>
      </p>
      <div v-for="(item, index) in items" :key="index">
        <div class="field" v-if="item.companyName">
          <p class="has-text-grey">Company name</p>
          <p>{{ item.companyName }}</p>
        </div>
        <div class="field" v-if="item.highRiskType">
          <p class="has-text-grey">High risk type</p>
          <p>{{ item.highRiskType }}</p>
        </div>
        <div class="field" v-if="item.dateOfInterest">
          <p class="has-text-grey">Date of interest</p>
          <p>{{ getDate(item.dateOfInterest) }}</p>
        </div>
        <div class="field" v-if="item.contactNumber">
          <p class="has-text-grey">Contact number</p>
          <p>{{ item.contactNumber }}</p>
        </div>
        <div class="field" v-if="item.contactReference">
          <p class="has-text-grey">Contact reference</p>
          <p>{{ item.contactReference }}</p>
        </div>
        <div class="field" v-if="item.registrationPeriod">
          <p class="has-text-grey">Period</p>
          <p>{{ item.registrationPeriod }}</p>
        </div>
        <div class="field" v-if="item.extraInfo">
          <p class="has-text-grey">Extra info</p>
          <p>{{ item.extraInfo }}</p>
        </div>
        <hr class="hr is-marginless" v-if="items.length > 1" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckHighRisk',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    risk() {
      return this.data.highRiskCount
    },
    items() {
      return this.data?.highRiskItems
    }
  },
  methods: {
    getDate: value => formatDate(value, 'yyyy/MM/dd')
  }
}
</script>
